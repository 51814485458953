.slides {
    position: relative;
    color: black;
}

.slides .slick-prev, .slides .slick-next {
    position: absolute;
    top: 50%;
}

.slides .slick-prev {
    z-index: 1;
}

.slides .slick-next {
    z-index: 1;
}

.slides .slick-prev:before, .slides .slick-next:before {
    color: darkgrey;
}

.card-img-top {
    width: auto;
    height: auto;
}

.card-header {
    min-height: 112px;
}

.list-unstyled-lead {
    height: 72px;
}